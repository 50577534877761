const orgMetadataAnnotation = 'annotations.cloud.streamnative.io/organization-metadata'
import { auth } from '@/auth'

export interface OrgMetadata {
  industry: string
  size: string
}

const isUserEmailVerified = async () => {
  return auth.user.value?.email_verified
}

/**
 * Returns true if logged in user has at least one org or not.  This can be used
 * to indirectly deteremine if an user is invited or not invited at the signup
 * because auth0 user object doesn't have any indiciation of invite or not.
 *
 * @returns If an user has an org or not
 */
export const isUserHasOrg = async (): Promise<boolean> => {
  const { setSelfSubjectUserReview, selfSubjectUsers } = useOrganization()
  await setSelfSubjectUserReview(true)
  return selfSubjectUsers.value.length > 0
}

export const getActiveOrgMetadata = computed<OrgMetadata | undefined>(() => {
  const activeOrganization = useOrganization().activeOrganization.value
  if (!activeOrganization) {
    return undefined
  }

  const annotations = activeOrganization.metadata?.annotations
  if (!annotations || annotations[orgMetadataAnnotation]) {
    return undefined
  }

  return JSON.parse(annotations[orgMetadataAnnotation])
})

export const useMetadata = () => {
  return {
    isUserEmailVerified,
    isUserHasOrg,
    getActiveOrgMetadata
  }
}
