import axios from 'axios'

const ACCESS_TOKEN_KEY = '_token'
const USER_KEY = '_user'
const REDIRECT_CACHE_KEY = '_redirect'
let AUTH_ENABLED_INIT = false
let AUTH_ENABLED = true
let USERPASS_AUTH_ENABLED = false

function searchJSON() {
  if (!window.location.search) {
    return {}
  }
  const pairs = window.location.search.substring(1).split('&')
  const obj = {}
  for (const i in pairs) {
    if (pairs[i] === '') continue
    const pair = pairs[i].split('=')
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }
  return obj
}

const cacheRedirect = target => {
  const params = searchJSON()
  if (target) {
    localStorage.setItem(REDIRECT_CACHE_KEY, target)
  } else if (params.redirect) {
    localStorage.setItem(REDIRECT_CACHE_KEY, params.redirect)
  }
}

const getRedirect = () => localStorage.getItem(REDIRECT_CACHE_KEY) || '/'

const cleanCache = () => {
  localStorage.removeItem(REDIRECT_CACHE_KEY)
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(USER_KEY)
}

export const authmethods = async () => {
  const {
    data: { vault, google, azure, okta }
  } = await axios.get('/cloud-manager/login/enabled')
  const {
    data: { methods }
  } = await axios.get('/cloud-manager/authmethods')
  const jwt = methods && methods.length > 0 && methods[0] == 'pulsar-jwt'
  return {
    vault,
    google,
    azure,
    okta,
    jwt
  }
}

export const userpassLogin = async (username, password) => {
  try {
    const {
      headers: { token, username: _username, organization, instance }
    } = await axios.post('/cloud-manager/login', {
      username,
      password,
      role: 'default',
      type: 'pulsar-vault-userpass'
    })
    const un = _username || 'anonymous'
    location.href =
      getRedirect() +
      `?token=${token}&username=${un}&organization=${organization}&instance=${instance}`
  } catch (error) {
    useError(error)
  }
}

export const jwtLogin = async jwtToken => {
  try {
    const {
      headers: { token, username: _username, organization, instance }
    } = await axios.post('/cloud-manager/login', {
      password: '',
      token: jwtToken,
      role: 'default',
      type: 'pulsar-jwt'
    })
    const un = _username || 'anonymous'
    location.href =
      getRedirect() +
      `?token=${token}&username=${un}&organization=${organization}&instance=${instance}`
  } catch (error) {
    useError(error)
  }
}

export const googleLogin = async () => {
  cacheRedirect()
  location.href = '/oauth2/authorization/google'
}

export const azureLogin = async () => {
  cacheRedirect()
  location.href = '/oauth2/authorization/azure'
}

export const oktaLogin = async () => {
  cacheRedirect()
  location.href = '/oauth2/authorization/okta'
}

export const loginWithRedirect = options => {
  const {
    appState: { target }
  } = options
  cleanCache()
  cacheRedirect(target)
  if (window.location.pathname != '/login') {
    location.href = '/login?redirect=' + getRedirect()
  }
}

export const logout = () => {
  loginWithRedirect({ appState: { target: '/' } })
}

export const checkSession = async router => {
  if (window.location.pathname == '/login' && (await isAuthenticated())) {
    const _redirect = getRedirect()
    localStorage.removeItem(REDIRECT_CACHE_KEY)
    window.history.replaceState({}, '', '/')
    if (router) {
      router.push(_redirect)
    }
  }
}

export const handleRedirectCallback = () => {
  const params = searchJSON()
  if (params.token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, params.token)
    if (params.username) {
      const user = {
        name: params.username
      }
      localStorage.setItem(USER_KEY, JSON.stringify(user))
    }
    if (params.login == 'success') {
      const _redirect = getRedirect()
      localStorage.removeItem(REDIRECT_CACHE_KEY)
      return {
        appState: { target: _redirect }
      }
    }
  }
  return null
}

export const isAuthenticated = async () => {
  if (!AUTH_ENABLED_INIT) {
    const { vault, google, azure, okta, jwt } = await authmethods()
    AUTH_ENABLED = vault || google || azure || okta || jwt
    USERPASS_AUTH_ENABLED = vault
    AUTH_ENABLED_INIT = true
  }
  if (!AUTH_ENABLED) {
    return true
  }
  return getTokenSilently()
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem(USER_KEY)) || { name: 'anonymous' }
}

export const getTokenSilently = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const getIdTokenClaims = () => {
  return {}
}

export const isAuthEnabled = () => {
  return AUTH_ENABLED
}

export const isUserpassAuthEnabled = () => {
  return USERPASS_AUTH_ENABLED
}

export default () => {
  return {
    authmethods,
    userpassLogin,
    jwtLogin,
    googleLogin,
    azureLogin,
    oktaLogin,
    loginWithRedirect,
    logout,
    checkSession,
    handleRedirectCallback,
    isAuthenticated,
    isAuthEnabled,
    isUserpassAuthEnabled,
    getUser,
    getTokenSilently,
    getIdTokenClaims
  }
}
