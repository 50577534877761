import { useCloudApi } from '@/composables/cloudApi'
import { createInstance, deleteInstance, fetchInstances } from '@/api/instances'
import { useAnalytics } from '@/composables/analytics'
import { i18n } from '@/lang'
import { useI18n } from 'vue-i18n'
import type {
  V1alpha1PoolOption,
  V1alpha1PulsarInstance
} from '@streamnative/cloud-api-client-typescript'
import type { V1alpha1PoolRef } from '@streamnative/cloud-api-client-typescript'
import AsyncLock from 'async-lock'
import { useIntervalFn } from '@vueuse/core'
import { getErrorMessage } from '@/utils/apiHelper'
import type { PulsarState } from './usePulsarState'
import { clearLastViewedOrgIns } from '@/utils/localStorageHelper'

let lastOrg: string | undefined = undefined
const { t } = i18n.global
const api = useCloudApi()
const { findPool } = usePools()
const instances = ref<Array<V1alpha1PulsarInstance>>([])
const poolOptions = ref<Array<V1alpha1PoolOption>>([])
const activeInstance = computed(() => {
  const { instance } = usePulsarState()
  const foundInstance = instances.value.find(i => i.metadata?.name === instance.value)

  if (foundInstance) {
    return foundInstance
  }
  if (!foundInstance && instance.value) {
    // instance is set but not found
    clearLastViewedOrgIns()
    // cannot use router as router may not be initialized.
    window.location.replace(window.location.origin)
  }
  return {}
})
const instanceLocations = computed(() => {
  const instanceObj = activeInstance.value
  const poolRefName = instanceObj.spec?.poolRef?.name
  const poolRefNamespace = instanceObj.spec?.poolRef?.namespace
  if (!poolRefName || !poolRefNamespace) {
    return []
  }
  const locations = new Set<string>()
  const poolRefNameToMatchWith = `${poolRefNamespace}-${poolRefName}`

  poolOptions.value
    .filter(poolOption => poolOption.metadata?.name === poolRefNameToMatchWith)
    .forEach(poolOption => {
      poolOption.spec?.locations.forEach(poolOptionLocation => {
        if (poolOptionLocation.location) {
          locations.add(poolOptionLocation.location)
        }
      })
    })

  return Array.from(locations)
})

export const INSTANCE_DETAILS: Record<string, Record<string, Record<string, string>>> = {
  regions: {
    GCP: {
      value: 'GCP',
      label: 'Google Cloud',
      cloudProvider: 'gcloud',
      icon: 'iconfont icon-google_cloud-icon'
    },
    AWS: {
      value: 'AWS',
      label: 'AWS',
      cloudProvider: 'aws',
      icon: 'iconfont icon-aws'
    }
  },
  plans: {
    FREE: {
      name: 'instance.free',
      value: 'FREE'
    },
    STANDARD: {
      name: 'instance.standard',
      value: 'STANDARD'
    }
  },
  zonals: {
    zonal: {
      value: 'zonal',
      label: 'instance.saz',
      azValue: 'SAZ'
    },
    regional: {
      value: 'regional',
      label: 'instance.maz',
      azValue: 'MAZ'
    }
  }
} as const

const setInstances = (_instances: Array<V1alpha1PulsarInstance>) => {
  instances.value = _instances.sort((a, b) => {
    if (!a.metadata?.name || !b.metadata?.name) return 0
    if (a.metadata.name < b.metadata.name) {
      return -1
    }
    if (a.metadata.name > b.metadata.name) {
      return 1
    }
    return 0
  })
}

const getPoolOptions = async ({ organization }: { organization: string }) => {
  const options = await api.listNamespacedPoolOption(organization)
  poolOptions.value = options.data.items
}

export const removeInstance = async (organization: string, instance: string) => {
  const { getFlinkClusterMap, getClusterMap, flinkClusters, clusterMap } = useCluster()
  await getFlinkClusterMap({ organization: organization })
  if (flinkClusters.value.find(fc => fc.instance === instance)) {
    throw Error(t('instance.unableToDeleteWithFlink'))
  }

  await getClusterMap({ organization: organization })
  if ((clusterMap.value[instance] ?? []).filter(clus => !clus.deleted).length > 0) {
    throw Error(t('instance.unableToDeleteActiveCluster'))
  }

  const data = await deleteInstance({ organization, instanceName: instance })

  useAnalytics().identifyUser()

  if (!data || data.status === 'Failure') {
    throw Error(t('instance.errorDelete'))
  }
  instances.value.filter(i => i?.metadata?.name !== instance)
}

export const addInstance = async (payload: {
  instance: string
  availabilityMode: 'zonal' | 'regional'
  type: 'FREE' | 'STANDARD' | undefined
  poolRef?: V1alpha1PoolRef
  organization: string
}) => {
  if (!payload.instance) {
    throw t('instance.inputInstanceNameNotification')
  }
  if (!payload.poolRef) {
    throw t('instance.inputPoolNotification')
  }
  const newInstance = await createInstance(payload)

  useAnalytics().identifyUser()

  setInstances([...instances.value, newInstance])
}

export const getInstances = async ({ organization }: { organization: string }) => {
  const res = await fetchInstances(organization)
  setInstances(res.items)
  // track instance count change
  useAnalytics().identifyUser()
}

export const istioEnabled = computed(() => {
  const annotations = activeInstance.value?.metadata?.annotations
  return (
    !!annotations && annotations?.['annotations.cloud.streamnative.io/istio-enabled'] === 'true'
  )
})

export const isPaidInstance = computed(() => {
  return activeInstance.value.spec?.type !== 'free'
})

export const translatedInstanceDetails = computed(() => {
  const { t } = useI18n()
  if (!activeInstance.value.spec) {
    // It's possible that `translatedInstanceDetails()` getter is called before
    // activeInstance has been set.
    // Just return empty object here as it will get updated when instance is set.
    return {}
  }
  const { availabilityMode, type, poolRef } = activeInstance.value.spec
  const name = activeInstance.value.metadata?.name
  const { regions, plans, zonals } = INSTANCE_DETAILS

  if (!poolRef) {
    return {}
  }
  const pool = findPool(poolRef)
  let planName = undefined
  if (pool?.cloudType === 'gcloud') {
    planName = 'GCP'
  } else if (pool?.cloudType === 'aws') {
    planName = 'AWS'
  }

  return {
    name,
    availabilityMode: t(zonals[availabilityMode].label),
    type: type ? t(plans[type.toUpperCase()].name) : undefined,
    plan: planName ? regions[planName].label : undefined,
    cloudProvider: planName ? regions[planName].cloudProvider : undefined
  }
})

export const audience = computed(() => {
  const { organization, instance } = usePulsarState()
  if (!instance.value || !activeInstance.value.status) {
    return undefined
  }
  return (
    activeInstance.value.status?.auth?.oauth2?.audience ??
    `urn:sn:pulsar:${organization.value}:${instance.value}`
  )
})

export const instanceReady = computed(() => {
  const condition = activeInstance.value.status?.conditions?.find(c => c.type === 'Ready')
  return condition ? condition.status === 'True' : false
})

export const instanceNames = computed(() => {
  return instances.value.map(instance => instance.metadata?.name ?? '')
})

export const hasInstances = computed(() => {
  return instances.value.length > 0
})

// locally scoped composable for pinging a cluster
export const usePingInstance = (params: { organization?: string; instance: string }) => {
  const { mustOrganization } = usePulsarState()
  const instance = ref<V1alpha1PulsarInstance>()
  const conditions = computed(() => instance.value?.status?.conditions ?? [])
  const error = ref('')
  const lock = new AsyncLock()
  const { resume, pause, isActive } = useIntervalFn(async () => {
    if (!lock.isBusy(params.instance)) {
      await lock.acquire(params.instance, async () => {
        try {
          const { data } = await useCloudApi().readNamespacedPulsarInstance(
            params.instance,
            params.organization ?? mustOrganization()
          )

          instance.value = data
        } catch (e) {
          error.value = getErrorMessage(e)
          pause()
        }
      })
    }
  }, 5000)

  return {
    instance,
    conditions,
    resume,
    pause,
    isActive,
    error
  }
}

export const init = (initialState: PulsarState) => {
  const { organization, instance } = usePulsarState()
  const valueChanged = async ([org, ins]: [string | undefined, string | undefined]) => {
    if (!org) {
      instances.value = []
      poolOptions.value = []
      lastOrg = undefined
      return
    }

    const isOrgChanged = org !== lastOrg
    const isInstanceMissing = ins ? !instances.value.find(i => i.metadata?.name === ins) : false

    if (isOrgChanged || isInstanceMissing) {
      await getInstances({ organization: org })
      await getPoolOptions({ organization: org })
    }
    lastOrg = org
  }

  watch([organization, instance], valueChanged)
  return valueChanged([initialState.organization, initialState.instance])
}

export const useInstance = () => {
  return {
    instances,
    instanceLocations,
    activeInstance,
    poolOptions,
    removeInstance,
    addInstance,
    getInstances,
    istioEnabled,
    isPaidInstance,
    translatedInstanceDetails,
    audience,
    instanceReady,
    instanceNames,
    hasInstances,
    setInstances,
    init
  }
}
