import { _createServiceAccountBinding } from '@/api/serviceaccounts'
import { i18n } from '@/lang'
import type { PulsarState } from './usePulsarState'

import getAxiosInstance from '@/utils/axios'
import type { AxiosInstance } from 'axios'

const { t } = i18n.global

export interface ServiceAccount {
  name: string
  organization: string
  token: { jwtToken: string; expiry: number } | undefined
  createTime: string
  privateKeyData: string | undefined
  clientEmail: string
  status: string
  disabled: boolean
  isSuper: boolean | undefined
}

export interface ServiceAccountConfig {
  client_email: string
  client_id: string
  client_secret: string
  issuer_url: string
  type: string
}

export interface Auth0ClientFlowPayload extends ServiceAccountConfig {
  audience: string
  grant_type: string
}

let lastOrg: string | undefined = undefined
const serviceAccounts = ref<Array<ServiceAccount>>([])
const activeAccount = ref<null | ServiceAccount>(null)
const { activeCluster } = useCluster()
export const emptyEmail = 'Empty'

const serviceAccountRoleEmails = computed<Array<string>>(() => {
  return serviceAccounts.value
    .map(account => account.clientEmail)
    .filter(email => email !== emptyEmail)
})
const availableAccounts = computed<Array<ServiceAccount>>(() => {
  return serviceAccounts.value.filter(account => account.status === 'Created')
})
const activeAccountKeyData = computed<Auth0ClientFlowPayload>(() => {
  if (activeAccount.value?.privateKeyData) {
    return JSON.parse(window.atob(activeAccount.value?.privateKeyData))
  }

  return null
})

const axios: AxiosInstance = getAxiosInstance()

const getServiceAccounts = async () => {
  const organization = usePulsarState().mustOrganization()
  const {
    data: { data }
  } = await axios.get('/cloud-manager/v1/serviceaccounts')
  serviceAccounts.value = data.map(({ name }: ServiceAccount) => {
    const _cache = serviceAccounts.value
      ? serviceAccounts.value.find(item => {
          return item.name == name
        })
      : undefined
    return {
      name: name,
      clientEmail: name,
      organization: organization,
      ..._cache,
      status: 'Created',
      disabled: false
    }
  }) as ServiceAccount[]
}

const getServiceAccount = async ({ name, customPayload }: { name: string, customPayload?: boolean }) => {
  const organization = usePulsarState().mustOrganization()
  const {
    data: { data }
  } = await axios.get('/cloud-manager/v1/serviceaccounts/' + name)

  const instance = usePulsarState().mustInstance()
  let _token = data.token
  let _privateKeyData = ''
  if (data.secretId && !customPayload) {
    _token = window.btoa(data.id + ':' + data.secretId)
    _privateKeyData = window.btoa(_token + ':' + organization + ':' + instance)
  }
  activeAccount.value = {
    ...activeAccount.value,
    isSuper: data.role === 'admin',
    token: { jwtToken: _token },
    privateKeyData: _privateKeyData
  } as ServiceAccount
  serviceAccounts.value = serviceAccounts.value.map(item => {
    if (item.name == name) {
      return {
        ...item,
        ...activeAccount.value
      } as ServiceAccount
    }
    return item
  })
}

const createServiceAccount = async ({
  name,
  superAdmin
}: {
  name: string
  superAdmin: boolean
}) => {
  if (name.length <= 3) {
    throw Error(t('serviceAccount.serviceAccountNameCheck'))
  }

  const organization = usePulsarState().mustOrganization()
  await axios.post('/cloud-manager/v1/serviceaccounts', { name, superAdmin })

  const newAccount: ServiceAccount = {
    name,
    organization,
    token: undefined,
    createTime: new Date().toISOString(),
    privateKeyData: '',
    clientEmail: 'Empty',
    status: 'Creating',
    disabled: true,
    isSuper: superAdmin
  }

  serviceAccounts.value = [...serviceAccounts.value, newAccount]
}

const deleteServiceAccount = async (name: string) => {
  try {
    await axios.delete('/cloud-manager/v1/serviceaccounts/' + name)
  } catch (error) {
    throw Error(t('serviceAccount.deleteServiceAccountFailedNotification'))
  }

  serviceAccounts.value = serviceAccounts.value.map(account => {
    if (account.name !== name) {
      return account
    } else {
      return { ...account, status: 'Deleting', disabled: true }
    }
  })
}

const saveFile = (data: string, filename: string) => {
  const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigatorObject: any = window.navigator
  if (navigatorObject && navigatorObject.msSaveBlob) {
    // IE 10+
    navigatorObject.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    // todo: this will always be true as HtmlAnchorElement.anything is always a string
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const saveAccountToFile = (row: { name: string; privateKeyData: string; organization: string }) => {
  const { track } = useAnalytics()
  track('service-accounts/key-file', { name: row.name })
  const jsonData = window.atob(row.privateKeyData)
  const filename = row.organization + '-' + row.name + '.json'
  saveFile(jsonData, filename)
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const valueChanged = async (org: string | undefined) => {
    if (!org) {
      serviceAccounts.value = []
      activeAccount.value = null
      lastOrg = undefined
      return
    }

    if (org !== lastOrg) {
      await getServiceAccounts(org)
    }
    lastOrg = org
  }

  watch(organization, valueChanged)
  return valueChanged(initialState.organization)
}

export const serviceAccountNames = computed(() => {
  return serviceAccounts.value.map(sa => sa.name)
})

export const ensureServiceAccountBindingExists = async (saName: string) => {
  if (!saName) {
    throw Error('service account is not defined')
  }
  if (!activeCluster.value) {
    throw Error('active cluster is not defined')
  }
  if (!activeCluster.value.poolMemberRef) {
    throw Error('activer cluster is missing poolMemberRef')
  }

  await _createServiceAccountBinding(
    saName,
    usePulsarState().mustOrganization(),
    activeCluster.value.poolMemberRef
  )
}

export const useServiceAccount = () => {
  return {
    serviceAccounts,
    activeAccount,
    serviceAccountRoleEmails,
    availableAccounts,
    activeAccountKeyData,
    serviceAccountNames,
    deleteServiceAccount,
    createServiceAccount,
    getServiceAccount,
    getServiceAccounts,
    saveAccountToFile,
    saveFile,
    ensureServiceAccountBindingExists,
    init
  }
}
