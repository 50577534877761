import axios from 'axios'
import type { TranslateResult } from 'vue-i18n'
import { i18n } from '@/lang'
const { t } = i18n.global
import { captureException } from '@/composables/sentry'

// the format of responses are different fort the various apis so we will define this function for each as applicable
export function getErrorMessage(e: unknown, fallbackMessage?: string | TranslateResult): string {
  // extract error meessage from kubernete.
  captureException(e, { level: 'log' })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _e = (e || {}) as any
  const kuberneteErrorMesage = _e.response?.responseJSON?.message
  if (e && axios.isAxiosError(e) && e.response?.data) {
    if (typeof _e.response.data === 'string' && (!_e.response.status || _e.response.status < 500)) {
      return _e.response.data.toString()
    }
    const { reason, message, error } = e.response.data as {
      reason?: string
      message?: string
      error?: string
    }
    if (message) return message.toString()
    if (reason) return reason.toString()
    if (error) return error.toString()
    if (_e.response.status >= 500) {
      return fallbackMessage?.toString() ?? `Request failed with ${_e.response.status} status`
    }
    return fallbackMessage?.toString() ?? e.toString()
  } else if (typeof e === 'string') {
    return e
  } else if (e instanceof Error) {
    return e.message
  } else if (kuberneteErrorMesage) {
    return kuberneteErrorMesage
  } else {
    return t('error.generic')
  }
}
