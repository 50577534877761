import type { Location, Pool } from '@/api/organizations'
import { getLocationsAndPools } from '@/api/organizations'
import type { PulsarState } from './usePulsarState'

let lastOrg: string | undefined = undefined
const locations = ref<Location[]>([])
const managedPools = ref<Location[]>([])
const pools = ref<Pool[]>([])

const locationsAndPoolsUpdated = (locationsAndPools: { locations: Location[]; pools: Pool[] }) => {
  locations.value = locationsAndPools.locations
  pools.value = locationsAndPools.pools
  managedPools.value = locations.value.filter(loc => loc.pool.poolRef.namespace !== 'streamnative')
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const valueChanged = async (org: string | undefined) => {
    if (!org) {
      locationsAndPoolsUpdated({ locations: [], pools: [] })
      lastOrg = undefined
      return
    }

    if (org !== lastOrg) {
      locationsAndPoolsUpdated(await getLocationsAndPools(org))
    }
    lastOrg = org
  }

  watch(organization, valueChanged)
  return valueChanged(initialState.organization)
}

export const findPool = (poolRef: { name: string; namespace: string } | undefined) => {
  if (!poolRef) {
    return undefined
  }
  return pools.value.find(
    p => p.poolRef.name === poolRef.name && p.poolRef.namespace === poolRef.namespace
  )
}

export const usePools = () => {
  return {
    locations,
    pools,
    managedPools,
    init,
    findPool
  }
}
