/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const init = (_a: any) => {}
export const setUser = (_a: any) => {}
export const setContext = (_a: any, _b: any) => {}
export const captureMessage = (_a: any, _b: any) => {}
export const captureException = (_a: any, _b: any) => {}

export const useSentry = () => {
  return {
    init,
    setUser,
    setContext,
    captureMessage,
    captureException
  }
}
