import type { LocationQuery, RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAnalytics } from '@/composables/analytics'
import { useWarn } from '@/composables/provider'
import type { PulsarState } from '@/composables/usePulsarState'
import { useMetadata } from '@/composables/useMetadata'
import { useUserMetadata } from '@/composables/useUserMetadata'
import { auth } from '@/auth'
import {
  setLastViewedOrgIns,
  clearLastViewedOrgIns,
  getLastViewedOrgIns,
  setSugerEntitlementId,
  setSugerPartner,
  setAwsMarketplaceToken
} from '@/utils/localStorageHelper'

// apply last visited org and instance if applicable before router initialization
const [lastVisitedOrg, lastVisitedIns] = getLastViewedOrgIns()
const searchParams = new URLSearchParams(window.location.search)
if (!searchParams.get('org') && lastVisitedOrg) {
  searchParams.set('org', lastVisitedOrg)
}
if (!searchParams.get('instance') && lastVisitedIns) {
  searchParams.set('instance', lastVisitedIns)
}
if (searchParams.get('sugerEntitlementId') && searchParams.get('partner')) {
  setSugerEntitlementId(searchParams.get('sugerEntitlementId') as string)
  setSugerPartner(searchParams.get('partner') as string)
}
if (searchParams.get('x-amzn-marketplace-token')) {
  setAwsMarketplaceToken(searchParams.get('x-amzn-marketplace-token') as string)
}
if (searchParams.toString() !== '') {
  await window.history.replaceState(history.state, '{}', `?${searchParams.toString()}`)
}
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

const getDesiredPulsarState = (to: RouteLocationNormalized): PulsarState => {
  return {
    organization: to.query.org ? (to.query.org as string) : undefined,
    instance: to.query.instance ? (to.query.instance as string) : undefined,
    clusterUid: to.query.cluster ? (to.query.cluster as string) : undefined,
    tenant: to.query.tenant ? (to.query.tenant as string) : undefined,
    namespace: to.query.namespace ? (to.query.namespace as string) : undefined,
    topic: to.query.topic ? (to.query.topic as string) : undefined
  }
}

const resolvePulsarState = async (
  to: RouteLocationNormalized
): Promise<RouteLocationNormalized | undefined> => {
  // fallthrough is desired here in this switch statement
  /* eslint-disable no-fallthrough */
  switch (to.name) {
    case 'TopicPage':
      if (!to.query.topic) {
        useWarn('Topic is missing')
        to.name = 'TopicsPage'
        return to
      }
    case 'TopicsPage':
    case 'NamespaceSettings':
    case 'NamespaceOverview':
    case 'NamespacePolicies':
    case 'TenantsList':
    case 'TenantsPage':
    case 'ClientsPage':
    case 'SecretsPage':
    case 'ConnectorsPage':
    case 'SinksPage':
    case 'SourcesPage':
      if (!to.query.cluster) {
        useWarn('Cluster is missing')
        to.name = 'InstancesPage'
        return to
      }
    case 'ClustersPage':
      if (!to.query.instance) {
        // required instance value is not passed in and not in cache
        useWarn('Instance is missing or not ready')
        to.name = 'InstancesPage'
        return to
      }
    case 'ServiceAccountsPage':
    case 'UsersPage':
    case 'InstancesPage':
    case 'DashboardPage':
      if (!to.query.org) {
        // required org value is not passed in go to singupflow page first
        // if no signup work is needed, will be redirected to organizations
        to.name = 'OrganizationsPage'
        return to
      }
    case 'OrganizationsPage':
    default:
      break
  }
}

// setup tracking for all route transitions automatically.
// also, to set some of the "default" values when applicable and redirect users to it.
const resolveDefaultQueryParams = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): [LocationQuery, boolean] => {
  useAnalytics().trackPage(to, from)

  const desiredQuery: Record<string, string | undefined> = {}
  const { organization, instance, clusterUid, tenant, namespace, topic } = usePulsarState()
  const { activeCluster, clusterMap } = useCluster()
  desiredQuery.org = organization.value

  // these are to avoid ?org=abc&cluster=123 issue.  This happens if api query fetch
  // complets before route navigation completes
  if (desiredQuery.org) {
    desiredQuery.instance = instance.value
  }
  if (desiredQuery.instance) {
    // first, try to set cluster uid from pulsar state or active cluster
    desiredQuery.cluster = clusterUid.value ?? activeCluster.value?.uid
    if (!desiredQuery.cluster) {
      // if still missing, try to set to first of the instance
      desiredQuery.cluster = clusterMap.value[desiredQuery.instance]?.[0]?.uid
    }
  }
  if (desiredQuery.cluster) {
    desiredQuery.tenant = tenant.value ?? 'public'
  }
  if (desiredQuery.tenant) {
    desiredQuery.namespace = namespace.value ?? 'default'
  }
  if (desiredQuery.namespace) {
    desiredQuery.topic = topic.value
  }

  let isUpdateNeeded = false

  if ((desiredQuery.org ?? '') !== ((to.query.org ?? '') as string)) {
    isUpdateNeeded = true
  }
  if ((desiredQuery.instance ?? '') !== ((to.query.instance ?? '') as string)) {
    isUpdateNeeded = true
  }
  if ((desiredQuery.cluster ?? '') !== ((to.query.cluster ?? '') as string)) {
    isUpdateNeeded = true
  }
  if ((desiredQuery.tenant ?? '') !== ((to.query.tenant ?? '') as string)) {
    isUpdateNeeded = true
  }
  if ((desiredQuery.namespace ?? '') !== ((to.query.namespace ?? '') as string)) {
    isUpdateNeeded = true
  }
  if ((desiredQuery.topic ?? '') !== ((to.query.topic ?? '') as string)) {
    isUpdateNeeded = true
  }

  const q = cleanQuery(desiredQuery)
  return [q, isUpdateNeeded]
}

const cleanQuery = (q: Record<string, string | undefined>) => {
  return Object.entries(q)
    .filter(([, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj[key] = value
      return obj
    }, {})
}
router.beforeResolve(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (!to.meta.requiresAuth) {
    return
  }

  if (auth.isAuthenticated && to.name !== 'SignupFlowPage') {
    const isUserHasOrg = await useMetadata().isUserHasOrg()
    const isUserHasMetadata = await useUserMetadata().isUserHasMetadata()
    if (auth.user?.value?.email_verified && (!isUserHasOrg || !isUserHasMetadata)) {
      // If a user doesn't have org or doesn't have metadata, this means that we
      // need to redirect our user and have them fill out necessary details.
      await router.replace({
        name: 'SignupFlowPage',
        query: { ...to.query, destination: '/' }
      }) // user has org(s) already and needs to create new one with Suger/aws
    }
  }

  const ret = await resolvePulsarState(to)
  const { sync } = usePulsarState()
  await sync(getDesiredPulsarState(to))
  if (ret) {
    const [q, updateNeeded] = resolveDefaultQueryParams(ret, from)
    if (!updateNeeded) {
      setLastViewedOrgIns(to)
      return { ...ret }
    }
    ret.query = q
    setLastViewedOrgIns(to)
    return { ...ret, replace: true }
  }

  const [q, updateNeeded] = resolveDefaultQueryParams(to, from)
  if (!updateNeeded) {
    setLastViewedOrgIns(to)
    return
  }
  to.query = q
  setLastViewedOrgIns(to)
  return { ...to, replace: true }
})

export default router
