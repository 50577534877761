import { createApp } from 'vue'
import { Quasar, Notify, Loading } from 'quasar'
import router from '@/router'
import { i18n } from '@/lang' // Internationalization
import { createMetaManager } from 'vue-meta'
import { auth } from '@/auth'
import InlineSvg from 'vue-inline-svg'
import '@quasar/extras/material-icons/material-icons.css'
import '@/assets/quasar.css'
import './index.css'
import App from './App.vue'
import * as Sentry from '@/composables/sentry'

const app = createApp(App)
app.use(Quasar, {
  plugins: {
    Notify,
    Loading
  }, // import Quasar plugins and add here
  config: {
    removeDefaultCss: true,
    brand: {
      primary: '#0A74F5',
      secondary: '#26A69A',
      accent: '#9C27B0',

      dark: '#1d1d1d',

      positive: '#1C8113',
      negative: '#C71F1F',
      info: '#31CCEC',
      warning: '#FFC233'
    },
    notify: { position: 'top' }, // default set of options for Notify Quasar plugin
    loading: { delay: 200 } // default set of options for Loading Quasar plugin
    // loadingBar: { ... }, // settings for LoadingBar Quasar plugin
    // ..and many more (check Installation card on each Quasar component/directive/plugin)
  }
})
app.use(router)
app.use(auth)
app.use(i18n)
app.use(createMetaManager())
app.component('InlineSvg', InlineSvg)

if (location.hostname !== 'console.local.sn') {
  Sentry.init({
    app,
    dsn: 'https://d1082fec54f343aaa123e78861a0387d@o1261076.ingest.sentry.io/6438081',
    integrations: [
      // // Startingw without tracing.
      // new BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //   tracingOrigins: ['console.local.sn', 'console.streamnative.cloud', /^\//]
      // })
    ],
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    environment: location.hostname // using hostname as environment
  })
}


/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
router.isReady().then(() => {
  app.mount('#app')
})
