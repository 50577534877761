import { QueryResult } from 'prometheus-query'
import getAxiosInstance from '../utils/axios'
import { auth } from '@/auth'
import proxyConfig from 'Config/proxy_config.json'

const axios = getAxiosInstance()
axios.interceptors.request.use(
  async config => {
    const { getAccessTokenSilently } = auth
    const { audience } = useInstance()
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: audience.value,
        scope: 'admin'
      }
    })
    if (!config.headers) {
      config.headers = {}
    }
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  async e => {
    return Promise.reject(e)
  }
)

const PRIVATE_CLOUD_METRICS_PARAMS = 'sum by (__name__)({__name__=~"pulsar_topics_count|pulsar_subscriptions_count|pulsar_producers_count|pulsar_consumers_count|pulsar_rate_in|pulsar_rate_out|pulsar_throughput_in|pulsar_throughput_out|pulsar_storage_size|pulsar_storage_backlog_size",+component="broker",namespace!="sn/system"})'

export async function getPulsarClusterMetrics(start: number, end: number, step = 300) {
  return await axios
    .get(`/api/v1/query_range?query=${PRIVATE_CLOUD_METRICS_PARAMS}&start=${start}&end=${end}&step=${step}`)
    .then(function (response) {
      return QueryResult.fromJSON(response.data.data)
    })
    .catch(function (error) {
      throw Error(getErrorMessage(error))
    })
}

export async function getTopicsMetrics(
  tenant: string,
  namespace: string,
  topics: { scheme: string; partitioned: boolean; name: string }[],
  start: number,
  end: number
) {
  const topicsString = encodeURIComponent(JSON.stringify(topics))
  return await axios
    .get(
      proxyConfig.proxyLocation +
        `/cloud/metrics/query?&start=${start}&end=${end}&pulsar_tenant=${tenant}&pulsar_namespace=${namespace}&topics=${topicsString}`
    )
    .then(function (response) {
      return QueryResult.fromJSON(response.data.data)
    })
}
