import { useBillingApi } from '@/composables/cloudApi'
import type { V1alpha1CustomerPortalRequest } from '@streamnative/cloud-api-client-typescript'
import { logoutCallback } from 'Config/auth_config.json'

export const createCustomerPortalRequest = async (org: string) => {
  const billingApi = useBillingApi()

  const body: V1alpha1CustomerPortalRequest = {
    apiVersion: 'billing.streamnative.io/v1alpha1',
    kind: 'CustomerPortalRequest',
    metadata: {
      generateName: 'request-',
      namespace: org
    },
    spec: {
      // to be replaced by `replace.sh`
      returnURL: `${logoutCallback}/instances?org=${org}`
    }
  }

  return await billingApi.createNamespacedCustomerPortalRequest(org, body)
}
