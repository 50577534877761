/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { RouteLocationNormalized, NavigationFailure } from 'vue-router'

const _use = {
  track: (_name: string, _props: any = {}) => {},
  trackPage: (
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    _failure: void | NavigationFailure | undefined
  ) => {},
  trackPageTab: (
    _category: string,
    _pageName: string,
    _tabIndex: string,
    _isValidationFail = false
  ) => {},
  identifyUser: () => {}
}

export const useAnalytics = () => {
  return _use
}
