import { useTenantsPulsarAdmin } from '@/composables/pulsarAdmin'
import type { TenantInfo } from '@streamnative/pulsar-admin-client-typescript'

const buildTenantsPulsarAdmin = (
  organization: string | undefined = usePulsarState().organization.value,
  clusterUid: string | undefined = usePulsarState().clusterUid.value
) => {
  if (!organization) {
    throw Error(`Organization is not set.`)
  }
  if (!clusterUid) {
    throw Error(`Cluster is not set.`)
  }
  return useTenantsPulsarAdmin(organization, clusterUid)
}

export const fetchTenants = async ({
  organization,
  clusterUid
}: {
  organization?: string
  clusterUid?: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.get()
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenants Error')
  }
}

export const fetchTenantAdmin = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.getTenantAdmin(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'fetchTenantAdmin Error')
  }
}

export const createTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.createTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}

export const updateTenant = async ({
  organization,
  clusterUid,
  tenant,
  data
}: {
  organization?: string
  clusterUid?: string
  tenant: string
  data: TenantInfo
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.updateTenant(tenant, data)
  } catch (e) {
    throw getErrorMessage(e, 'updateTenant Error')
  }
}

export const deleteTenant = async ({
  organization,
  clusterUid,
  tenant
}: {
  organization?: string
  clusterUid?: string
  tenant: string
}) => {
  try {
    const tenantsPulsarAdmin = buildTenantsPulsarAdmin(organization, clusterUid)
    return await tenantsPulsarAdmin.deleteTenant(tenant)
  } catch (e) {
    throw getErrorMessage(e, 'createTenant Error')
  }
}
