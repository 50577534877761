import axios from 'axios'
import { auth } from '@/auth'
import { getLastViewedOrgIns } from '@/utils/localStorageHelper'

export default function getAxiosInstance() {
  const axiosInstance = axios.create({ timeout: 60000 })
  axiosInstance.interceptors.request.use(async config => {
    if (!config.headers) {
      config.headers = {}
    }
    const { getAccessTokenSilently } = auth
    const token = await getAccessTokenSilently()
    const [organization, instance, cluster] = getLastViewedOrgIns()
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
      'x-pulsar-instance-token': token,
      'x-api-organization': organization,
      'x-api-instance': instance,
      'x-api-cluster': cluster,
      'x-api-language': 'en'
    }
    return config
  })
  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    async e => {
      const { isAuthenticated, logout } = auth
      if (e?.response?.status === 401 || !isAuthenticated.value) {
        await logout()
      }

      return Promise.reject(e)
    }
  )
  return axiosInstance
}
