import * as Sentry from '@/composables/sentry'

export interface PulsarState {
  organization?: string
  instance?: string
  clusterUid?: string
  tenant?: string
  namespace?: string
  topic?: string
}

export interface PulsarStateRedirectRequest {
  e: unknown
  newDestination: PulsarState
}

const organization = ref<PulsarState['organization']>(undefined)
const instance = ref<PulsarState['instance']>(undefined)
const clusterUid = ref<PulsarState['clusterUid']>(undefined)
const tenant = ref<PulsarState['tenant']>(undefined)
const namespace = ref<PulsarState['namespace']>(undefined)
const topic = ref<PulsarState['topic']>(undefined)

const mustOrganization = (): string => {
  if (organization.value) {
    return organization.value
  }
  throw Error('Organization is not set.')
}
const mustInstance = (): string => {
  if (instance.value) {
    return instance.value
  }
  throw Error('Instance is not set.')
}
const mustClusterUid = (): string => {
  if (clusterUid.value) {
    return clusterUid.value
  }
  throw Error('ClusterUid is not set.')
}
const mustTenant = (): string => {
  if (tenant.value) {
    return tenant.value
  }
  throw Error('tenant is not set.')
}
const mustNamespace = (): string => {
  if (namespace.value) {
    return namespace.value
  }
  throw Error('namespace is not set.')
}
const mustTopic = (): string => {
  if (topic.value) {
    return topic.value
  }
  throw Error('topic is not set.')
}
const sync = async (desiredState: PulsarState) => {
  const { setSelfSubjectUserReview } = useOrganization()

  try {
    // no need for async as it's only used in analytics
    await setSelfSubjectUserReview(true)
    organization.value = desiredState.organization
    instance.value = desiredState.instance
    clusterUid.value = desiredState.clusterUid
    tenant.value = desiredState.tenant
    namespace.value = desiredState.namespace
    topic.value = desiredState.topic
  } finally {
    Sentry.setContext('pulsar-state', {
      organization: organization.value,
      instance: instance.value,
      clusterUid: clusterUid.value,
      tenant: tenant.value,
      namespace: namespace.value,
      topic: topic.value
    })
  }
}

export const usePulsarState = () => {
  return {
    organization,
    mustOrganization,
    instance,
    mustInstance,
    clusterUid,
    mustClusterUid,
    tenant,
    mustTenant,
    namespace,
    mustNamespace,
    topic,
    mustTopic,
    sync
  }
}
