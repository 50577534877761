import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

let defaultLang = 'en'
const storedLang = localStorage.getItem('language')
if (storedLang === 'en' || storedLang === 'zh') {
  defaultLang = storedLang
}

export const i18n = createI18n({
  legacy: false,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
  locale: defaultLang,
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  messages
})
