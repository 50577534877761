import { createAuth0 } from './plugin'
import authConfig from 'Config/auth_config.json'

const urlParams = new URLSearchParams(window.location.search)
const defaultMethod = urlParams.get('defaultMethod')
const _auth = createAuth0({
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  authorizationParams: {
    redirect_uri: authConfig.callbackUrl,
    screen_hint: defaultMethod ? (defaultMethod as string).toLowerCase() : undefined,
    audience: authConfig.audience
  },
  cacheLocation: 'localstorage',
  // `useRefreshTokens` will break our e2e tests.  Since safari is the only one that needs
  // this value to be true, we will conditionally set this value to true only for safari.
  useRefreshTokens: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? true : false,
  useRefreshTokensFallback: true
})

export const auth = _auth
