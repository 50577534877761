import * as cloud from '@streamnative/pulsar-admin-client-typescript'
import type {
  TenantsApi,
  NamespacesApi,
  PersistentTopicApi,
  NonPersistentTopicApi
} from '@streamnative/pulsar-admin-client-typescript'
import { auth } from '@/auth'
import getAxiosInstance from '@/utils/axios'
import proxyConfig from 'Config/proxy_config.json'

const TENANTS_PARTITIONED = '/admin/v2/persistent/(.*)/(.*)/(.*)/partitions'
const axiosInstance = getAxiosInstance()
axiosInstance.interceptors.request.use(async config => {
  if (!config.headers) {
    config.headers = {}
  }

  const { getAccessTokenSilently } = auth
  const { audience } = useInstance()
  for (let i = 0; i < 3 && !config.headers['Authorization']; i++) {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: audience.value,
        scope: 'admin'
      }
    })
    const isTokenValid = token && token.length > 50 && token.indexOf('..') < 0
    if (isTokenValid) {
      config.headers['Authorization'] = `Bearer ${await getAccessTokenSilently({
        authorizationParams: {
          audience: audience.value,
          scope: 'admin'
        }
      })}`
    } else {
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
  }

  // openapi-generator from which the pulsar-admin client is generated has a bug
  // which does not allow for plain integer parameters to be passed to certain
  // json endpoints (see https://github.com/OpenAPITools/openapi-generator/issues/10991 for
  // details)
  // this is a workaround for this particular problem by simply converting the request data
  // to the proper format for problematic endpoints
  if ((config.url || '').search(TENANTS_PARTITIONED) >= 0 && config.data) {
    config.data = parseInt(JSON.parse(config.data))
  }
  return config
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getBasePath = (_org: string, _clusterId: string) => '/pulsar-admin'

export const useTenantsPulsarAdmin = (org: string, clusterId: string): TenantsApi => {
  return new cloud.TenantsApi(undefined, getBasePath(org, clusterId), axiosInstance)
}

export const useNamespacesPulsarAdmin = (org: string, clusterId: string): NamespacesApi => {
  return new cloud.NamespacesApi(undefined, getBasePath(org, clusterId), axiosInstance)
}

export const usePersistentTopicPulsarAdmin = (
  org: string,
  clusterId: string
): PersistentTopicApi => {
  return new cloud.PersistentTopicApi(undefined, getBasePath(org, clusterId), axiosInstance)
}

export const useNonPersistentTopicPulsarAdmin = (
  org: string,
  clusterId: string
): NonPersistentTopicApi => {
  return new cloud.NonPersistentTopicApi(undefined, getBasePath(org, clusterId), axiosInstance)
}

export const getTopicPulsarAdmin = (
  persitency: boolean,
  org: string,
  clusterId: string
): cloud.PersistentTopicApi | cloud.NonPersistentTopicApi => {
  if (persitency) {
    return usePersistentTopicPulsarAdmin(org, clusterId)
  } else {
    return useNonPersistentTopicPulsarAdmin(org, clusterId)
  }
}

export const getTopicPulsarAdminFromTopic = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topic: any
): cloud.PersistentTopicApi | cloud.NonPersistentTopicApi => {
  return getTopicPulsarAdmin(topic.persistent, topic.organization, topic.clusterUid)
}

export const useSchemaPulsarAdmin = (org: string, clusterId: string): cloud.SchemasApi => {
  return new cloud.SchemasApi(undefined, getBasePath(org, clusterId), axiosInstance)
}

export const useSinkApi = (): cloud.SinksApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.SinksApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    axiosInstance
  )
}

export const useSourceApi = (): cloud.SourcesApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.SourcesApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    axiosInstance
  )
}

export const useFunctionsApi = (): cloud.FunctionsApi => {
  const { organization, clusterUid } = usePulsarState()
  if (!organization.value || !clusterUid.value) {
    throw new Error('Organization or clusterUid is not set')
  }

  return new cloud.FunctionsApi(
    undefined,
    getBasePath(organization.value, clusterUid.value),
    axiosInstance
  )
}
