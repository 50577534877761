import type {
  V1alpha1UserList,
  V1alpha1User,
  V1Status,
  V1alpha1RoleBinding
} from '@streamnative/cloud-api-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'

export const getUserInfoByUsername = async (
  organization: string,
  username: string
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.readNamespacedUser(username, organization)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'getUserInfoByUsername Error'))
  }
}

export const fetchUsers = async (
  organization: string
): Promise<V1alpha1UserList | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.listNamespacedUser(organization)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'fetchUsers Error'))
  }
}

export const createUser = async (
  organization: string,
  user: V1alpha1User
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.createNamespacedUser(organization, user)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'createUser Error'))
  }
}

// export const updateUserInvite = async (username, namespace) => {
//   const api = await useCloudApi()
//   return api.readNamespacedUser(username, namespace)
// }

// export const resendInvite = async (username, namespace) => {
//   const api = await useCloudApi()
//   return api.readNamespacedUser(username, namespace)
// }

export const updateUser = async (
  organization: string,
  username: string,
  data: Array<Record<string, string>>
): Promise<V1alpha1User | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.patchNamespacedUser(username, organization, data)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'updateUser Error'))
  }
}

export const deleteUser = async (
  organization: string,
  username: string
): Promise<V1Status | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.deleteNamespacedUser(username, organization)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'deleteUser Failed'))
  }
}

export const createRoleBinding = async (
  organization: string,
  data: V1alpha1RoleBinding
): Promise<V1alpha1RoleBinding | { error: string }> => {
  try {
    const api = useCloudApi()
    const res = await api.createNamespacedRoleBinding(organization, data)
    return res.data
  } catch (e) {
    throw new Error(getErrorMessage(e, 'createRoleBinding Failed'))
  }
}
