import { useAuthApi, useCloudApi } from '@/composables/cloudApi'
import type { V1alpha1PoolRef } from '@streamnative/cloud-api-client-typescript'
import type { AxiosError } from 'axios'
import { i18n } from '@/lang'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const { t } = i18n.global

export const fetchSelfSubjectUserReview = async () => {
  try {
    const api = useAuthApi()
    return await api.createSelfSubjectUserReview({}) // need dummy object
  } catch (e) {
    // return
    throw getErrorMessage(e, 'fetchOrganizations Error')
  }
}

export const fetchOrganization = async (namespace: string) => {
  try {
    const api = useCloudApi()
    return await api.readOrganization(namespace)
  } catch (e) {
    if ((e as AxiosError).response?.status === 403) {
      // When organization is not ready, it also throws 403.
      throw Error(t(`organization.notification.notReady`))
    }
    throw Error(getErrorMessage(e, 'getOrganization error'))
  }
}

export interface Location {
  location: string
  label: string
  pool: Pool
}
export interface Pool {
  cloudType: string
  label: string
  poolRef: V1alpha1PoolRef
}
export const getLocationsAndPools = async (organization: string) => {
  const locations: Location[] = []
  const pools: Pool[] = []
  const api = useCloudApi()
  const { data } = await api.listNamespacedPoolOption(organization)

  for (let i = 0; i < data.items.length; i++) {
    const item = data.items[i]
    if (!item.spec) {
      throw Error(t('dashboard.errorGettingLocations'))
    }
    if (item.spec.locations.length === 0) {
      continue
    }

    const pool: Pool = {
      cloudType: item.spec?.cloudType,
      label: `${item.spec?.cloudType} - ${item.spec?.poolRef.namespace}/${item.spec?.poolRef.name}`,
      poolRef: item.spec?.poolRef
    }
    pools.push(pool)
    for (let j = 0; j < item.spec.locations.length; j++) {
      const location: Location = {
        location: item.spec?.locations[j].location,
        label: `${pool.label}/${item.spec?.locations[j].location}`,
        pool
      }
      locations.push(location)
    }
  }
  return { locations, pools }
}
