import {
  useNonPersistentTopicPulsarAdmin,
  usePersistentTopicPulsarAdmin,
  useSchemaPulsarAdmin
} from '@/composables/pulsarAdmin'
import type { PostSchemaPayload } from '@streamnative/pulsar-admin-client-typescript'
interface TopicApiParameters {
  organization: string
  clusterUid: string
  tenant: string
  namespace: string
  persistent: boolean
  partitioned?: boolean
}
interface TopicActionParameters extends TopicApiParameters {
  name: string
  partition?: number
}

const buildTopicPulsarAdmin = ({
  organization,
  clusterUid,
  persistent
}: {
  organization: string
  clusterUid: string
  persistent: boolean
}) => {
  return persistent
    ? usePersistentTopicPulsarAdmin(organization, clusterUid)
    : useNonPersistentTopicPulsarAdmin(organization, clusterUid)
}

const buildSchemaPulsarAdmin = ({
  organization,
  clusterUid
}: {
  organization: string
  clusterUid: string
}) => {
  return useSchemaPulsarAdmin(organization, clusterUid)
}

export const getTopics = async (params: TopicApiParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedTopicList(params.tenant, params.namespace)
  } else {
    // case: persistent === false
    // This one is odd, sometimes topics will include partitioned topics sometimes they don't
    // example payload with partition: ["non-persistent://a/b/c1", "no-persistent://a/b/c2-partition-0", "non-persistent://a/b/c2-partition-1"]
    // example payload without partition: ["non-persistent://a/b/c1"]
    return admin.getList(params.tenant, params.namespace)
  }
}

export const createTopic = async (params: TopicActionParameters & { partitionCount: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.createPartitionedTopic(
      params.tenant,
      params.namespace,
      params.name,
      params.partitionCount
    )
  } else {
    return admin.createNonPartitionedTopic(params.tenant, params.namespace, params.name)
  }
}

export const updateTopic = async (params: TopicActionParameters & { partitionCount: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.updatePartitionedTopic(
      params.tenant,
      params.namespace,
      params.name,
      params.partitionCount
    )
  }
}

export const deleteTopic = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.deletePartitionedTopic(params.tenant, params.namespace, params.name)
  } else {
    return admin.deleteTopic(params.tenant, params.namespace, params.name)
  }
}

export const fetchTopicStats = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedStats(params.tenant, params.namespace, params.name, true)
  } else {
    return admin.getStats(params.tenant, params.namespace, params.name)
  }
}

export const createSubscription = async (
  params: TopicActionParameters & { subscriptionName: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.createSubscription(
    params.tenant,
    params.namespace,
    params.name,
    params.subscriptionName
  )
}

export const deleteSubscription = async (
  params: TopicActionParameters & { subscriptionName: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.deleteSubscription(
    params.tenant,
    params.namespace,
    params.name,
    params.subscriptionName
  )
}

export const resetCursor = async (
  params: TopicActionParameters & { subscriptionName: string; timestamp: number }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.resetCursor(
    params.tenant,
    params.namespace,
    params.name,
    params.subscriptionName,
    params.timestamp
  )
}

export const skipAllMessages = async (
  params: TopicActionParameters & { subscriptionName: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.skipAllMessages(
    params.tenant,
    params.namespace,
    params.name,
    params.subscriptionName
  )
}

export const getTopicBundle = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getTopicBundle(params.tenant, params.namespace, params.name)
}

export const getTopicBroker = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getTopicBroker(params.tenant, params.namespace, params.name)
}

export const fetchAllSchemas = async (params: TopicActionParameters) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.getAll(params.tenant, params.namespace, params.name)
}

export const deleteSchema = async (params: TopicActionParameters) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.deleteSchema(params.tenant, params.namespace, params.name)
}

export const postSchema = async (params: TopicActionParameters & { body: PostSchemaPayload }) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.postSchema(params.tenant, params.namespace, params.name, undefined, {
    type: params.body.type,
    schema: params.body.schema,
    properties: params.body.properties
  })
}

export const getInternalStats = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedStatsInternal(params.tenant, params.namespace, params.name)
  }
  return admin.getInternalStats(params.tenant, params.namespace, params.name)
}

export const unloadTopic = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.unloadTopic(params.tenant, params.namespace, params.name)
}

export const compactionStatus = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.compactionStatus(params.tenant, params.namespace, params.name)
}

export const compact = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.compact(params.tenant, params.namespace, params.name)
}

export const getPermissionsOnTopic = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getPermissionsOnTopic(params.tenant, params.namespace, params.name)
}

export const grantPermissionsOnTopic = async (
  params: TopicActionParameters & { role: string; permissions: string[] }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.grantPermissionsOnTopic(
    params.tenant,
    params.namespace,
    params.name,
    params.role,
    params.permissions
  )
}

export const revokePermissionsOnTopic = async (
  params: TopicActionParameters & { role: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.revokePermissionsOnTopic(params.tenant, params.namespace, params.name, params.role)
}

export const getPartitionedMetadata = async (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getPartitionedMetadata(params.tenant, params.namespace, params.name)
}

export const peekNthMessage = async (
  params: TopicActionParameters & {
    subscriptionName: string
    n: number
  }
) => {
  const admin = buildTopicPulsarAdmin(params)

  // returns binrary data
  return await admin.peekNthMessage(
    params.tenant,
    params.namespace,
    params.name,
    params.subscriptionName,
    params.n,
    undefined,
    { responseType: 'arraybuffer' }
  )
}
