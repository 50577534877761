import { fetchTenants } from '@/api/tenants'
import { fetchNamespacePolicies, fetchNamespaces } from '@/api/namespaces'
import { useError } from '@/composables/provider'
import type { Policies } from '@streamnative/pulsar-admin-client-typescript'
import type { Cluster } from './useCluster'
import type { PulsarState } from './usePulsarState'

let lastClusUid: string | undefined = undefined

interface TenantNamespaces {
  [tenant: string]: { [namespace: string]: Policies }
}
const tenantNamespaces = ref<TenantNamespaces>({})
const tenantNames = computed<string[]>(() => Object.keys(tenantNamespaces.value).sort())
const setTenants = async ({
  organization,
  clusterUid
}: {
  organization: string
  clusterUid: string
}) => {
  try {
    const result: TenantNamespaces = {}
    const tenantsList = (await fetchTenants({ organization, clusterUid })).data
    tenantsList.forEach(tenant => {
      result[tenant] = {}
    })
    tenantNamespaces.value = result
  } catch {
    tenantNamespaces.value = {}
  }
}

const namespacesLoading = ref(false)
const setTenantNamespaces = async (tenant: string) => {
  namespacesLoading.value = true
  try {
    if (Object.keys(tenantNamespaces.value).length === 0) {
      tenantNamespaces.value[tenant] = {}
    }
    if (Object.keys(tenantNamespaces.value[tenant]).length === 0) {
      const result: { [namespace: string]: Policies } = {}
      const namespaceList = (await fetchNamespaces({ tenant })).data
      namespaceList.forEach(namespace => {
        result[namespace] = {}
      })
      tenantNamespaces.value[tenant] = result
    }
  } catch (e) {
    useError(e)
    tenantNamespaces.value[tenant] = {}
  } finally {
    namespacesLoading.value = false
  }
}

const setNamespacePolicies = async (params: { tenant: string; namespace: string }) => {
  const { mustOrganization, mustClusterUid } = usePulsarState()

  const policies = (
    await fetchNamespacePolicies({
      organization: mustOrganization(),
      clusterUid: mustClusterUid(),
      ...params
    })
  ).data

  if (!(params.tenant in tenantNamespaces.value)) {
    tenantNamespaces.value[params.tenant] = {}
  }
  tenantNamespaces.value[params.tenant][params.namespace] = policies
}

export const init = (initialState: PulsarState) => {
  const { organization, tenant } = usePulsarState()
  const { activeCluster } = useCluster()
  const valueChanged = async ([org, cluster, ten]: [
    string | undefined,
    Cluster | undefined,
    string | undefined
  ]) => {
    const isClusterReady = cluster?.conditions.Ready ?? false
    const clusterUid = cluster?.uid ?? undefined
    if (!org || !isClusterReady || !clusterUid) {
      tenantNamespaces.value = {}
      lastClusUid = undefined
      return
    }

    if (clusterUid !== lastClusUid) {
      lastClusUid = clusterUid
      await setTenants({ organization: org, clusterUid })
      return
    }

    if (ten) {
      await setTenantNamespaces(ten)
    }
    lastClusUid = clusterUid
  }

  watch([organization, activeCluster, tenant], valueChanged)
  return valueChanged([initialState.organization, activeCluster.value, initialState.tenant])
}

export const useTenantNamespace = () => {
  return {
    setTenants,
    namespacesLoading,
    setTenantNamespaces,
    tenantNames,
    tenantNamespaces,
    removeTenant: (name: string) => {
      delete tenantNamespaces.value[name]
    },
    addTenant: (name: string) => {
      tenantNamespaces.value[name] = {}
    },
    removeNamespace: (tenant: string, name: string) => {
      delete tenantNamespaces.value[tenant][name]
    },
    addNamespace: (tenant: string, name: string) => {
      tenantNamespaces.value[tenant][name] = {}
    },
    setNamespacePolicies,
    init
  }
}
