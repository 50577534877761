import type { Ref } from 'vue'
import { createUser, deleteUser, fetchUsers } from '@/api/users'
import { i18n } from '@/lang'
import { auth } from '@/auth'
import type { PulsarState } from './usePulsarState'
const { t } = i18n.global

export interface User extends UserCore {
  organization: string
  invited: boolean
  accepted: boolean
  isUser: boolean
  copyLink: string
  deleted: boolean
}

export interface UserCore {
  name: string
  email: string
  password?: string
}

let lastOrg: string | undefined = undefined
const users: Ref<User[]> = ref([])

const userNamesList = computed(() => {
  return users.value.map(user => user.name)
})

const setUserDeleted = (name: string) => {
  users.value.forEach(user => {
    if (user.name === name) {
      user.deleted = true
    }
  })
}

const annotationInviteLink = 'annotations.cloud.streamnative.io/invitation-data-invitationLink'
const accepted = 'accepted'

const getUsers = async () => {
  const organization = usePulsarState().mustOrganization()
  const result = await fetchUsers(organization)

  if ('error' in result) {
    throw Error(result.error)
  }

  const { user } = auth

  const _users: Array<User> = []
  result.items.forEach(({ metadata, spec }) => {
    if (metadata && spec) {
      _users.push({
        name: metadata.name ?? spec.email,
        organization: metadata.namespace ?? 'N/A',
        email: spec.email,
        invited: !!spec.invitation,
        accepted: spec?.invitation?.decision === accepted,
        isUser: metadata.name === user.value?.email,
        copyLink: metadata?.annotations?.[annotationInviteLink] ?? '',
        deleted: !!metadata.deletionTimestamp
      })
    }
  })

  users.value = _users
}
const inviteUser = async (payload: UserCore) => {
  const org = usePulsarState().mustOrganization()
  await createUser(org, { metadata: { ...payload } })
  users.value = [
    ...users.value,
    {
      name: payload.name,
      organization: org,
      email: payload.name,
      invited: true,
      accepted: true,
      isUser: false,
      copyLink: '',
      deleted: false
    }
  ]
}
const removeUser = async (username: string) => {
  const organization = usePulsarState().mustOrganization()
  const result = await deleteUser(organization, username)

  if ('error' in result) {
    throw Error(result?.error ?? t('user.deleteUserFailedNotification'))
  }

  setUserDeleted(username)
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const valueChanged = async (org: string | undefined) => {
    if (!org) {
      users.value = []
      lastOrg = undefined
      return
    }

    if (org !== lastOrg) {
      await getUsers(org)
    }
    lastOrg = org
  }

  watch(organization, valueChanged)
  return valueChanged(initialState.organization)
}

export const _useUser = {
  users,
  removeUser,
  inviteUser,
  getUsers,
  init,
  userNamesList
}

export const useUser = () => {
  return _useUser
}
