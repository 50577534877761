import type { RouteRecordRaw } from 'vue-router'

import { authGuard } from '@/auth/plugin'
// these routes are not authed
const openRoutes = <Array<RouteRecordRaw>>[
  // TODO remove me?
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/callback/failed',
    name: 'CallbackFailedPage',
    component: () => import('@/views/login/CallbackFailedPage.vue')
  },
  {
    path: '/callback',
    name: 'CallbackPage',
    component: () => import('@/views/login/CallbackPage.vue')
  },
  // TODO remove me?
  {
    path: '/error',
    name: 'errorPage',
    component: () => import('@/views/error/ErrorPage.vue')
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: () => import('@/views/error/NotFoundPage.vue')
  }
].map(r => {
  return {
    ...r,
    meta: { requiresAuth: false }
  }
})

const authRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'DashboardPage',
        component: () => import('@/views/dashboard/DashboardPage.vue')
      },
      {
        path: '/organizations',
        name: 'OrganizationsPage',
        component: () => import('@/views/organization/OrganizationsPage.vue')
      },
      {
        path: '/organization-usage',
        name: 'OrganizationUsagePage',
        component: () => import('@/views/organization/OrganizationUsagePage.vue')
      },
      {
        path: '/users',
        name: 'UsersPage',
        component: () => import('@/views/user/UsersPage.vue')
      },
      {
        path: '/instances',
        name: 'InstancesPage',
        component: () => import('@/views/instance/InstancesPage.vue')
      },
      {
        path: '/tenants',
        name: 'TenantsPage',
        component: () => import('@/views/tenant/TenantsPage.vue')
      },
      {
        path: '/clients',
        name: 'ClientsPage',
        component: () => import('@/views/client/ClientsPage.vue')
      },
      {
        path: '/kafka-clients',
        name: 'KafkaClientsPage',
        component: () => import('@/views/client/KafkaClientsPage.vue')
      },
      {
        path: '/clusters',
        name: 'ClustersPage',
        component: () => import('@/views/cluster/ClustersPage.vue')
      },
      {
        path: '/tenants',
        name: 'TenantsPage',
        component: () => import('@/views/tenant/TenantsPage.vue'),
        redirect: { name: 'TenantsList' },
        children: [
          {
            path: 'list',
            name: 'TenantsList',
            component: () => import('@/views/tenant/TenantsList.vue')
          },
          {
            path: ':tenant/namespaces/:namespace',
            name: 'NamespaceSettings',
            component: () => import('@/views/tenant/namespace/NamespaceSettings.vue'),
            redirect: { name: 'NamespaceOverview' },
            props: true,
            children: [
              {
                path: 'overview',
                name: 'NamespaceOverview',
                component: () => import('@/views/tenant/namespace/overview/NamespaceOverview.vue'),
                props: true
              },
              {
                path: 'policies',
                name: 'NamespacePolicies',
                component: () => import('@/views/tenant/namespace/policies/NamespacePolicies.vue'),
                props: true
              }
            ]
          }
        ]
      },
      {
        path: '/topics',
        component: () => import('@/views/topic/TopicsPage.vue'),
        name: 'TopicsPage'
      },
      {
        path: '/topic',
        component: () => import('@/views/topic/TopicPage.vue'),
        name: 'TopicPage',
        redirect: { name: 'TopicOverview' },
        children: [
          {
            path: '',
            name: 'TopicOverview',
            component: () => import('@/views/topic/overview/TopicOverview.vue')
          },
          {
            path: 'schema',
            name: 'TopicSchema',
            component: () => import('@/views/topic/schema/TopicSchema.vue')
          },
          {
            path: 'messages',
            name: 'TopicMessages',
            component: () => import('@/views/topic/messages/TopicMessages.vue')
          },
          {
            path: 'storage',
            name: 'TopicStorage',
            component: () => import('@/views/topic/storage/TopicStorage.vue')
          },
          {
            path: 'policies',
            name: 'TopicPolicies',
            component: () => import('@/views/topic/policies/TopicPolicies.vue')
          },
          {
            path: 'policies',
            name: 'TopicStats',
            component: () => import('@/views/topic/stats/TopicStats.vue')
          }
        ]
      },
      {
        path: '/service-accounts',
        component: () => import('@/views/service-account/ServiceAccountsPage.vue'),
        name: 'ServiceAccountsPage'
      },
      {
        path: '/connector',
        name: 'ConnectorsPage',
        redirect: { name: 'SinksPage' },
        component: () => import('@/views/connector/ConnectorsPage.vue'),
        children: [
          {
            path: 'sinks',
            name: 'SinksPage',
            component: () => import('@/views/connector/SinksPage.vue')
          },
          {
            path: 'sources',
            name: 'SourcesPage',
            component: () => import('@/views/connector/SourcesPage.vue')
          }
        ]
      },
      {
        path: '/functions',
        name: 'FunctionsPage',
        component: () => import('@/views/function/FunctionsPage.vue')
      },
      {
        path: '/pfsqls',
        name: 'PfSqlListPage',
        component: () => import('@/views/pfsql/PfSqlListPage.vue')
      },
      {
        path: '/pfsql/deploy',
        name: 'PfSqlDeployPage',
        component: () => import('@/views/pfsql/PfSqlDeployPage.vue')
      },
      {
        path: '/pfsql',
        name: 'PfSqlPage',
        component: () => import('@/views/pfsql/PfSqlPage.vue'),
        redirect: { name: 'PfSqlOverview' },
        children: [
          {
            path: '',
            name: 'PfSqlOverview',
            component: () => import('@/views/pfsql/PfSqlOverview.vue')
          },
          {
            path: 'logs',
            name: 'PfSqlLogs',
            component: () => import('@/views/pfsql/PfSqlLogs.vue')
          },
          {
            path: 'exceptions',
            name: 'PfSqlExceptions',
            component: () => import('@/views/pfsql/PfSqlExceptions.vue')
          }
        ]
      },
      {
        path: '/function',
        component: () => import('@/views/function/FunctionPage.vue'),
        name: 'FunctionPage',
        redirect: { name: 'FunctionOverview' },
        children: [
          {
            path: '',
            name: 'FunctionOverview',
            component: () => import('@/views/function/FunctionOverview.vue')
          },
          {
            path: 'logs',
            name: 'FunctionLogs',
            component: () => import('@/views/function/FunctionLogs.vue')
          },
          {
            path: 'exceptions',
            name: 'FunctionExceptions',
            component: () => import('@/views/function/FunctionExceptions.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/deploying',
    name: 'DeployingPage',
    component: () => import('@/views/provision/DeployingPage.vue'),
    props: true
  },
  {
    path: '/products',
    name: 'ProductsPage',
    component: () => import('@/views/provision/ProductsPage.vue')
  }
].map(r => {
  return {
    ...r,
    meta: { requiresAuth: true },
    beforeEnter: authGuard
  }
})

// this needs to added last and redirect to 404
const notFoundRoutes = <Array<RouteRecordRaw>>[
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

export default openRoutes.concat(authRoutes).concat(notFoundRoutes)
