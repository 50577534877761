import type { TranslateResult } from 'vue-i18n'
import type { QNotifyUpdateOptions } from 'quasar'
import { Notify, Loading, QSpinnerGears } from 'quasar'

export const useSuccess = (text: string): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: text,
    color: 'positive',
    attrs: {
      'data-ta-tag': 'use-success-notification'
    }
  })
}
export const useError = (
  error: unknown | string | TranslateResult
): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: getErrorMessage(error),
    color: 'negative',
    attrs: {
      'data-ta-tag': 'use-error-notification'
    }
  })
}
export const useWarn = (
  text: string | TranslateResult
): ((props?: QNotifyUpdateOptions) => void) => {
  return Notify.create({
    message: text,
    color: 'warning'
  })
}
export const useLoading = (): { close: () => void } => {
  Loading.show({
    spinner: QSpinnerGears
  })

  return {
    close: () => Loading.hide()
  }
}
