import * as vue from 'vue'
import authClient from './client'

/**
 * @ignore
 */
const AUTH0_TOKEN = '$auth0'
/**
 * Injection token used to `provide` the `Auth0VueClient` instance. Can be used to pass to `inject()`
 *
 * ```js
 * inject(AUTH0_INJECTION_KEY)
 * ```
 */
const AUTH0_INJECTION_KEY = Symbol(AUTH0_TOKEN)

// const version = '2.1.0'

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @ignore
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, the promise will resolve.
 */
function watchEffectOnceAsync(watcher) {
  return new Promise(resolve => {
    watchEffectOnce(watcher, resolve)
  })
}
/**
 * @ignore
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 */
function watchEffectOnce(watcher, fn) {
  const stopWatch = vue.watchEffect(() => {
    if (watcher()) {
      fn()
      stopWatch()
    }
  })
}
/**
 * @ignore
 * Helper function to bind methods to itself, useful when using Vue's `provide` / `inject` API's.
 */
function bindPluginMethods(plugin, exclude) {
  Object.getOwnPropertyNames(Object.getPrototypeOf(plugin))
    .filter(method => !exclude.includes(method))
    .forEach(method => (plugin[method] = plugin[method].bind(plugin)))
}
/**
 * @ignore
 * Helper function to map the v1 `redirect_uri` option to the v2 `authorizationParams.redirect_uri`
 * and log a warning.
 */
function deprecateRedirectUri(options) {
  if (options === null || options === void 0 ? void 0 : options.redirect_uri) {
    console.warn(
      'Using `redirect_uri` has been deprecated, please use `authorizationParams.redirect_uri` instead as `redirectUri` will be no longer supported in a future version'
    )
    options.authorizationParams = options.authorizationParams || {}
    options.authorizationParams.redirect_uri = options.redirect_uri
    delete options.redirect_uri
  }
}

/**
 * @ignore
 */
const client = vue.ref(null)
/**
 * @ignore
 */
class Auth0Plugin {
  constructor(clientOptions, pluginOptions) {
    this.clientOptions = clientOptions
    this.pluginOptions = pluginOptions
    this._isLoading = vue.ref(true)
    this._isAuthenticated = vue.ref(false)
    this._user = vue.ref({})
    this._idTokenClaims = vue.ref()
    this._error = vue.ref(null)
    this.isLoading = vue.readonly(this._isLoading)
    this.isAuthenticated = vue.readonly(this._isAuthenticated)
    this.user = vue.readonly(this._user)
    this.idTokenClaims = vue.readonly(this._idTokenClaims)
    this.error = vue.readonly(this._error)
    // Vue Plugins can have issues when passing around the instance to `provide`
    // Therefor we need to bind all methods correctly to `this`.
    bindPluginMethods(this, ['constructor'])
  }
  install(app) {
    this._client = authClient()
    this.__checkSession(app.config.globalProperties.$router)
    app.config.globalProperties[AUTH0_TOKEN] = this
    app.provide(AUTH0_INJECTION_KEY, this)
    client.value = this
  }
  async loginWithRedirect(options) {
    deprecateRedirectUri(options)
    return this._client.loginWithRedirect(options)
  }
  async logout(options) {
    if (
      (options === null || options === void 0 ? void 0 : options.openUrl) ||
      (options === null || options === void 0 ? void 0 : options.openUrl) === false
    ) {
      return this.__proxy(() => this._client.logout(options))
    }
    return this._client.logout(options)
  }
  /* istanbul ignore next */
  async getAccessTokenSilently(options = {}) {
    deprecateRedirectUri(options)
    return this.__proxy(() => this._client.getTokenSilently(options))
  }
  async checkSession(options) {
    return this.__proxy(() => this._client.checkSession(options))
  }
  async handleRedirectCallback(url) {
    return this.__proxy(() => this._client.handleRedirectCallback(url))
  }
  async __checkSession(router) {
    const search = window.location.search
    if (search.includes('token=')) {
      const result = await this.handleRedirectCallback()
      const target = result ? result.appState.target : '/'
      window.history.replaceState({}, '', '/')
      if (router) {
        router.push(target)
      }
      return result
    } else {
      await this.checkSession(router)
    }
  }
  async __refreshState() {
    this._isAuthenticated.value = await this._client.isAuthenticated()
    this._user.value = await this._client.getUser()
    this._idTokenClaims.value = await this._client.getIdTokenClaims()
    this._isLoading.value = false
  }
  async __proxy(cb, refreshState = true) {
    let result
    try {
      result = await cb()
      this._error.value = null
    } catch (e) {
      this._error.value = e
      throw e
    } finally {
      if (refreshState) {
        await this.__refreshState()
      }
    }
    return result
  }
}

async function createGuardHandler(client, to, redirectLoginOptions) {
  const fn = async () => {
    if (vue.unref(client.isAuthenticated)) {
      return true
    }
    await client.loginWithRedirect(
      Object.assign({ appState: { target: to.fullPath } }, redirectLoginOptions)
    )
    return false
  }
  if (!vue.unref(client.isLoading)) {
    return fn()
  }
  await watchEffectOnceAsync(() => !vue.unref(client.isLoading))
  return fn()
}

export async function authGuard(to) {
  const auth0 = vue.unref(client)
  return createGuardHandler(auth0, to)
}

/**
 * Creates the Auth0 plugin.
 *
 * @param clientOptions The Auth Vue Client Options
 * @param pluginOptions Additional Plugin Configuration Options
 * @returns An instance of Auth0Plugin
 */
export function createAuth0(clientOptions, pluginOptions) {
  deprecateRedirectUri(clientOptions)
  return new Auth0Plugin(clientOptions, pluginOptions)
}
/**
 * Returns the registered Auth0 instance using Vue's `inject`.
 * @returns An instance of Auth0VueClient
 */
export function useAuth0() {
  return vue.inject(AUTH0_INJECTION_KEY)
}
