<template>
  <metainfo>
    <template #title="{ content }">{{ content ? `${content}` : `StreamNative Cloud` }}</template>
  </metainfo>
  <router-view v-if="!loading" />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useAnalytics } from '@/composables/analytics'
import * as Sentry from '@/composables/sentry'
import type { PulsarState } from './composables/usePulsarState'
import { until } from '@vueuse/core'
import { auth } from './auth'

export default defineComponent({
  setup() {
    const { locale } = useI18n()
    const router = useRouter()
    const loading = ref<boolean>(true)
    useMeta({
      title: 'StreamNative Private Cloud',
      htmlAttrs: { lang: locale.value, amp: true }
    })

    const { user, isLoading } = auth
    const { identifyUser } = useAnalytics()

    onMounted(async () => {
      const loadingComponent = useLoading()
      await router.isReady()

      try {
        // composables may not have loaded at this point so using raw queryparam parsing for init
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        const pulsarState: PulsarState = {
          organization: params.org ? params.org : undefined,
          instance: params.instance ? params.instance : undefined,
          clusterUid: params.cluster ? params.cluster : undefined,
          tenant: params.tenant ? params.tenant : undefined,
          namespace: params.namespace ? params.namespace : undefined,
          topic: params.topic ? params.topic : undefined
        }

        // initialize some of the composables so that they can correctly watch PulsarState.
        // Depends on the auth initialization.
        await useOrganization().init(pulsarState)
        await useInstance().init(pulsarState)
        await useCluster().init(pulsarState) // some composables depends on the activeCluster so ensure useCluster completion
        await Promise.all([
          useSecret().init(pulsarState),
          useServiceAccount().init(pulsarState),
          useUser().init(pulsarState),
          usePools().init(pulsarState),
          useTenantNamespace().init(pulsarState),
          useTopic().init(pulsarState)
        ])

        // When a user selects an instance but not cluster, we selects first cluster within an instance for them.
        // This logic is to handle that specific case before sub components of apps loads and everything starts
        // loading before it's ready.
        const activeUid = useCluster().activeCluster.value?.uid
        if (params.org && params.instance && !params.cluster && activeUid) {
          await router.replace({
            query: {
              org: params.org ?? '',
              instance: params.instance,
              cluster: activeUid,
              tenant: 'public',
              namespace: 'default'
            }
          })
        }

        if (user.value.email) {
          Sentry.setUser({
            email: user.value.email,
            id: user.value.sub,
            name: user.value.name
          })
        }

        identifyUser()
      } catch (e) {
        useError(e)
      } finally {
        loading.value = false
        loadingComponent.close()
      }
    })

    return {
      loading
    }
  }
})
</script>
